import { TableCellPosition, TableColumn } from '@eui/table';
import { City } from '@emrm/common/types';

export type LeadUserSegmentTableCell =
	| string
	| number
	| boolean
	| City[]
	| null;

export const LEAD_USER_SEGMENTS_TABLE_HEADERS: TableColumn[][] = [
	[
		{
			id: 'id',
			property: 'id',
			title: '',
			resizable: false,
			fixed: true,
			minWidth: 40,
		},
		{
			id: 'counter_name',
			property: 'counter_name',
			title: 'Счетчик / Номер счетчика',
			fixed: true,
			resizable: true,
			expand: true,
			minWidth: 240,
		},
		{
			id: 'segment_name',
			property: 'segment_name',
			title: 'Название сегмента',
			fixed: false,
			resizable: true,
			minWidth: 240,
		},
		{
			id: 'cities',
			property: 'cities',
			title: 'Город источника заявок',
			resizable: true,
			fixed: false,
			minWidth: 300,
		},
		{
			id: 'goal',
			title: 'Название цели',
			resizable: true,
			fixed: false,
			minWidth: 240,
		},
		{
			id: 'goal_js',
			title: 'ID Цели',
			resizable: true,
			fixed: false,
			minWidth: 240,
		},
		{
			id: 'count_numbers',
			title: 'Переданные номера, шт.',
			resizable: true,
			fixed: false,
			minWidth: 200,
			position: TableCellPosition.Right,
		},
		{
			id: 'updated_at',
			title: 'Дата обновления',
			resizable: true,
			fixed: false,
			minWidth: 160,
		},
		{
			id: 'author_name',
			title: 'Создатель',
			resizable: true,
			fixed: false,
			minWidth: 160,
		},
		{
			id: 'delete_action',
			title: '',
			resizable: false,
			fixed: false,
			minWidth: 40,
		},
	],
];
